<template>
  <div class="wrapper-box">
    <el-card>
      <el-table ref="multiTable" :data="apprMeData" border v-loading="loading">
        <el-table-column label="单据编号" prop="form_no" width="120px"/>
        <el-table-column label="发起人说明" prop="appr_inst_info"  :show-overflow-tooltip='true' />
        <el-table-column label="审批时间" prop="update_time" width="280px" :formatter="formatDate"/>
        <el-table-column label="单据状态" prop="form_no" width="120px" align="center">
          <template slot-scope="scope">
            <el-tag :type="helper.getInstNextNode(scope.row.appr_inst_next_node).type" size="mini" class="vd_kid">{{helper.getInstNextNode(scope.row.appr_inst_next_node).name}}</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center" width="120px">
          <template slot-scope="scope">
            <el-button type="text" size="small" icon="el-icon-right" @click="goAppr(scope.row.appr_inst_url)">详情</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-card>
  </div>
</template>

<script>
import {get} from "@api/request";
import {apprAPI} from "@api/modules/appr";
export default {
  name: "boardAllRight",
  data(){
    return{
      apprMeData: [],
      loading: true,
    }
  },
  created() {
    this.initData()
  },
  methods:{
    initData(){
      this.getAllApproveMe()
    },
    // 获取待审批记录（全部）
    getAllApproveMe(){
      get(apprAPI.getApproveMe,{limit:100})
          .then(res=>{
            if(res.data.code === 0){
              this.loading = false
              this.apprMeData = res.data.data
            } else {
              let mg = res.data.msg;
              let tp = 'error';
              this.$message({message: mg, type: tp});
            }
          })
          .catch((res)=>{
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({message: mg, type: tp});
          })
    },
    formatDate(row) {
      return this.helper.toStringDate(row.update_time)
    },
    goAppr(val){
      this.jump(val)
    },
  }
}
</script>

<style scoped>

</style>